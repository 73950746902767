<template>
  <b-card title="Pagos por Certificaciones">
    <!--<b-card-text>{{selected}}</b-card-text>-->
    <b-row>
      <b-col md="6" class="mb-1">
        <!-- <b-button variant="primary" v-b-modal.modal-pago class="mr-1">
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Nuevo</span>
        </b-button> -->

        <b-button
          @click="actualizarLista()"
          variant="secondary"
          class="btn-icon mr-1"
        >
          <feather-icon icon="RotateCcwIcon" />
        </b-button>
      </b-col>

      <b-col md="4">
        <b-row>
          <b-col cols="6">
            <b-form-group label-cols="3" label-cols-lg="3" label="Del">
              <flat-pickr v-model="del" class="form-control" />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-cols="3" label-cols-lg="3" label="Al">
              <flat-pickr v-model="al" class="form-control" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <!-- <b-col md="3" class="mb-1">
        <v-select
          label="title"
          placeholder="Filtrar por Tipo"
          v-model="categoria_id"
          :options="categoriaOpciones"
        />
      </b-col> -->
      <b-col md="2">
        <b-form-group
          label=""
          label-align-sm="right"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              @keyup.enter="onFiltered()"
              placeholder="Buscar.."
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12" class="my-1">
        <b-overlay :show="loading">
          <b-table
            class="position-relative sm"
            responsive="sm"
            ref="selectableTable"
            selectable
            select-mode="single"
            hover
            striped
            :small="true"
            :items="items"
            :fields="fields"
            show-empty
            empty-text="No se encontraron registros coincidentes"
            @filtered="onFiltered"
          >
            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <i class="feather icon-disc primary" />
              </template>

              <template v-else>
                <i class="feather icon-circle" />
              </template>
            </template>

            <template #cell(verificado)="data">
              <b-badge :variant="verificado[1][data.value]">
                {{ verificado[0][data.value] }}
              </b-badge>
            </template>

            <template #cell(afiliado)="data">
              {{ data.item.afiliado.apellidos }} {{ data.item.afiliado.nombres }}
              <br>
              <strong>R.N.: {{ data.item.afiliado.rn }}</strong>
            </template>

            <template #cell(detalle_pagos)="data">
              <div v-for="(detalle, index) in data.item.detalle_pagos" :key="index">
                  {{ detalle.nombre_concepto_pago }}
              </div>
            </template>

            <template #cell(estado)="data">
              <b-badge :variant="estado[1][data.value]">
                {{ estado[0][data.value] }}
              </b-badge>
            </template>

            <template #cell(comprobante_pago)="data">
              <!-- {{ data.item.comprobante_pago.nro_comprobante ? data.item.comprobante_pago.nro_comprobante : '-' }} -->
              <b-button
                v-if="data.item.comprobante_pago"
                v-b-modal.modal-datos-comprobante
                @click="abrirModalComprobante(data.item.comprobante_pago)"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-info"
                v-b-tooltip.hover.bottom="'Datos Comprobante'"
                class="btn-icon mr-1"
                size="sm"                 
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <span v-else>-</span>
            </template>

            <template #cell(accion)="data">
              <div class="text-nowrap">                
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-info"
                  @click="pago = data.item"
                  v-b-tooltip.hover.bottom="'Ver Recibo'"
                  class="btn-icon mr-1"
                  size="sm"
                  v-b-modal.modal-recibo
                >
                  <feather-icon icon="PrinterIcon" />
                </b-button>

                <b-button
                  v-if="data.item.verificado != 1"
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="outline-success"
                  @click="cambiarVerificado(data.item.id, 1)"
                  v-b-tooltip.hover.bottom="'Marcar como Verificado'"
                  class="btn-icon mr-1"
                  size="sm"
                >
                  <feather-icon icon="CheckIcon" />
                </b-button>
                <b-button
                  v-else
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="outline-dark"
                  @click="cambiarVerificado(data.item.id, 0)"
                  v-b-tooltip.hover.bottom="'Marcar No Verficado'"
                  class="btn-icon mr-1"
                  size="sm"
                >
                  <feather-icon icon="XIcon" />
                </b-button>

                <b-button
                  v-if="$hascan('pago.anular')"
                  @click="confirmar_anular(data.item.id)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  v-b-tooltip.hover.bottom="'Anular'"
                  class="btn-icon"
                  size="sm"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
            </template>

            <template #cell()="data">
              <span class="text-nowrap">{{ data.value }}</span>
            </template>
          </b-table>
        </b-overlay>
      </b-col>
      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          class="my-0"
        />
      </b-col>
      <PagoNuevo 
        @cerrarComponente="cerrarComponente" 
        @actualizarLista="actualizarLista"
      />
      <DatosComprobante 
        :item="item"
      />
      <VisorRecibo 
        :pago="pago"
        @cerrarComponente="pago=null"
      />

    </b-row>
  </b-card>
</template>

<script>
import axiosIns from "@/libs/axios";
import {
  BCard,
  BCardText,
  BTable,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BOverlay,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
const Spanish = require('flatpickr/dist/l10n/es');
import PagoNuevo from '@/views/aplicaciones/pagos/PagoNuevo.vue';
import DatosComprobante from "./DatosComprobante.vue";
import VisorRecibo from "./VisorRecibo.vue";

export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BOverlay,
    vSelect,
    flatPickr,
    PagoNuevo,
    DatosComprobante,
    VisorRecibo
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      accion: 'registrar',
      item: null,
      items: [],
      fields: [
        { key: "created_at", label: "Registrado", sortable: false },
        { key: "afiliado", label: "Nombres y Apellidos", sortable: false },
        { key: "verificado", label: "Estado", sortable: false },
        { key: "monto", label: "Monto Total", sortable: false },
        { key: "detalle_pagos", label: "Detalle Pago", sortable: false },

        { key: "fecha_pago", label: "Fecha Pago", sortable: false },
        { key: "tipo_pago", label: "Tipo Pago", sortable: false },
        { key: "comprobante_pago", label: "Comprobante", sortable: false },
        "accion",
      ],
      verificado: [{
        1: 'Verificado', 0: 'Sin Verificar'
      },
      {
        1: 'light-success', 0: 'light-danger',
      }],
      selected: [],
      filter: "",
      filterOn: [],
      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      loading: false,
      estado: [
        {
          1: "Publicado",
          2: "Borrador",
          3: "Papelera",
        },
        {
          1: "light-primary",
          2: "light-warning",
          3: "light-danger",
        },
      ],
      item: [],
      categoria_id: "",
      categoriaOpciones: [],
      del: null,
      al: null,
      tipoEntradaId:1,
      reciboBase64: null,
      pago: null,
    };
  },
  created() {
    this.loading = true;
    this.rango_fechas();
    // this.listar();    
    // this.listarCategorias();
  },
  watch: {
    categoria_id: function () {
      this.loading = true;
      this.listar();
    },
    del: function () {
      if (!this.loading) {
        this.loading = true;
        this.listar();
      }
    },
    al: function () {
      if (!this.loading) {
        this.loading = true;
        this.listar();
      }
    },
    currentPage: function () {
      if (!this.loading) {
        this.loading = true;
        this.listar();
      }
    },
    filter: function (val) {
      if (val == "" || val.length == 0) {
        this.loading = true;
        this.listar();
      }
    },
  },
  methods: {
    
    async mensaje(Mensaje,tipo) {
      await this.$swal({
        title: Mensaje,

        icon: tipo,
        timer: 3000,
        confirmButtonText: "Aceptar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    
    onFiltered() {
      if (this.currentPage != 1) {
        this.currentPage = 1;
      } else {
        this.loading = true;
        this.listar();
      }
    },
    listar() {
      let categoria = "";
      if (
        this.categoria_id == null ||
        this.categoria_id == "" ||
        this.categoria_id == "undefined"
      ) {
        categoria = "";
      } else {
        categoria = this.categoria_id.value;
      }
      var url =
        "/admin/pagos?tipo_cobro=unico&page=" +
        this.currentPage +
        "&categoria=" +
        categoria +
        "&del=" +
        this.del +
        "&al=" +
        this.al +
        "&nombre=" +
        this.filter;
      axiosIns
        .get(url)
        .then((res) => {
          this.items = res.data.data;
          this.currentPage=res.data.current_page;
          this.totalRows = res.data.total;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cerrarComponente(){
      this.accion='';
      this.item=[];
    },
    actualizarLista() {
      this.loading = true;
      this.listar();
    },
    listarCategorias() {
      var url= '/admin/categorias?tipoEntradaId='+this.tipoEntradaId;
      axiosIns
        .get(url)
        .then((res) => {
          this.categoriaOpciones = res.data.map((g) => ({
            title: g.nombre,
            value: g.id,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // verRecibo(pago_id) {
    //   var url= '/admin/pagos/recibo/'+pago_id;
    //   axiosIns
    //     .get(url)
    //     .then((res) => {
    //       this.pdfBase64 = res.data.pdf;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    confirmar_anular(id) {
      this.$swal({
        title: "Advertencia",
        text: "Confirme que esta seguro de anular?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.anular(id);
        }
      });
    },
    anular(id) {
      axiosIns
        .delete("/admin/pagos/" + id)
        .then((res) => {
          this.$bvToast.toast("Exito", {
            title: "Se anuló correctamente",
            variant: "success",
            solid: false,
          });
          this.actualizarLista();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cambiarVerificado(id, verificado) {
      this.$swal({
        title: "Advertencia",
        text: "Confirme para actualizar el estado de verificación",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosIns
            .patch("/admin/pagos/cambiar-verificado/" + id, { verificado: verificado })
            .then((res) => {
              this.$bvToast.toast("Exito", {
                title: "Se actualizó correctamente",
                variant: "success",
                solid: false,
              });
              this.actualizarLista();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });      
    },

    rango_fechas() {
      axiosIns
        .get("/admin/rango-fechas-pagos")
        .then((res) => {
          this.del = res.data[0];
          this.al = res.data[1];
          this.listar();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    
    abrirModalComprobante(item){
      this.item = item;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>